'use client';
import { TrackProvider } from '@americastestkitchen/ui/app/TrackProvider';
import Footer from '@americastestkitchen/ui/footer/index';
import trackUI from 'components/layouts/ui-layout/trackUI';
import { FF_DIGITAL_APP_LANDING_PAGE } from 'server/lib/feature-flags';

export default function UIFooterV2() {
  return (
    <TrackProvider track={trackUI}>
      <Footer linkFarmFeatureFlag={FF_DIGITAL_APP_LANDING_PAGE} />
    </TrackProvider>
  );
}
