'use client';
import { liteClient as algoliasearch } from 'algoliasearch-new/lite';
import trackUI from 'components/layouts/ui-layout/trackUI';
import Header from '@americastestkitchen/ui/header';
import { usePathname } from 'next/navigation';
import { useUserHeader } from 'components/layouts/ui-layout/useUserHeader';
import { FF_DIGITAL_APP_LANDING_PAGE } from 'server/lib/feature-flags';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';
const client = algoliasearch(APP_ID, API_KEY, {});

function getUserObject(user: any) {
  if (!user || user.reducedSegment === 'anonymous') {
    return { authenticated: false, member: false };
  }

  return {
    firstName: user.first_name ?? '',
    lastName: user.last_name ?? '',
    authenticated: true,
    member: user && user.reducedSegment === 'multisite',
  };
}

export default function UIHeaderV2({ strapiData }: { strapiData?: any }) {
  const user = useUserHeader();
  const asPath = usePathname() || undefined;
  return (
    <Header
      currentPath={asPath}
      algoliaClient={client}
      track={trackUI}
      linkFarmFeatureFlag={FF_DIGITAL_APP_LANDING_PAGE}
      user={getUserObject(user)}
      strapiData={strapiData}
    />
  );
}
